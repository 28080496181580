<template>
	<v-app style='background:transparent !important'>
		<icons/>
		<router-view :key="$route.path + num"></router-view>
	</v-app>
</template>

<style>

	.meta.pubDate{
		position: absolute;
    	right: 1em;
	}
	.bg-a{
		background-color:rgb(215,215,210);
		z-index:-1;
		width:100%;
		height:100%;
		position:fixed;
		top:0;
		left:0;
	}

	.list-item .wol-title{
		padding:1em;
		padding-bottom:0.2em;
	}
	.v-tab{
		white-space: nowrap !important;
	}


	.logo-font{
		font-family: Righteous;
	}

	.v-list-item.primary--text.v-list-item--active.v-list-item--link.theme--light{
		color:inherit !important;
		caret-color:inherit !important;
	}

	html{
		overflow-y:auto !important;
	}

	.no-padding{
		padding:0 !important;
	}
	.upper{
		text-transform: uppercase;
	}
	.f-400{
		font-weight: 400;
	}
	.f-600{
		font-weight: 600;
	}
	
	/* esto afecta a los gráficos de dona */
	.v-application{
		font-family:'Poppins', 'Roboto',sans-serif !important;
	}
	.v-application h2{
		font-weight:500;
	}
	.v-application a{
		color:inherit !important;
	}
	svg text {
		font-family: Poppins, Roboto, sans-serif !important;
	}


	h1,h2,h3,h4{
		font-weight: 400;
	}
	.audioPlayerOptions{
		display: none;
	}
	.musicTOCFilterArea{
		display: none;
	}
	.musicGrid{
		display: none;
	}

	.wol-content .j-button-primary{
		color:white !important;
	}
.col-d1 {
    flex: 0 0 10%;
    max-width: 10%;
}
.col-d2 {
    flex: 0 0 20%;
    max-width: 20%;
}
.col-d3 {
    flex: 0 0 30%;
    max-width: 30%;
}
.col-d4 {
    flex: 0 0 40%;
    max-width: 40%;
}
.col-d5 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-d6 {
    flex: 0 0 60%;
    max-width: 60%;
}
.col-d7 {
    flex: 0 0 70%;
    max-width: 70%;
}
.col-d8 {
    flex: 0 0 80%;
    max-width: 80%;
}
.col-d9 {
    flex: 0 0 90%;
    max-width: 90%;
}
.col-d10 {
    flex: 0 0 100%;
    max-width: 100%;
}


.col-h1 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
}
.col-h2 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
.col-h3 {
    flex: 0 0 18.75%;
    max-width: 18.75%;
}
.col-h4 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-h5 {
    flex: 0 0 31.5%;
    max-width: 31.25%;
}
.col-h6 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-h7 {
    flex: 0 0 43.75%;
    max-width: 43.75%;
}
.col-h8 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-h9 {
    flex: 0 0 56.25%;
    max-width: 56.25%;
}
.col-h10 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
}
.col-h11 {
    flex: 0 0 68.75%;
    max-width: 68.75%;
}
.col-h12 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-h13 {
    flex: 0 0 81.25%;
    max-width: 81.25%;
}
.col-h14 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
}
.col-h15 {
    flex: 0 0 93.75%;
    max-width: 93.75%;
}
.col-h16 {
    flex: 0 0 100%;
    max-width:100%;
}


.wol-content h1{
    font-size: 1.5em;
}

.wol-content h2{
    font-size: 1.45em;
}

.wol-content h3{
    font-size: 1.35em;
}

.wol-content h4{
    font-size: 1.2em;
}

#galleryModalContainer .mediaTitle.narrow{
    display: none;
}
.article-top-related-image img{
	width:100% !important;
}

.hideObj{
	display: none;
}

.d-inline-block{
	text-indent: 0 !important;
}
</style>
<script>
import Icons from './components/Icons'
export default {
	components: {
		Icons
	},
	data(){
		this.vars = {
			num: 0
		}
		window.forceRender = ()=>{
			this.vars.num++
		}
		return this.vars
	}
}
</script>
