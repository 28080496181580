import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let base = "/ui"
if(location.pathname.startsWith("/best.kode.jwchoice"))
	base = "/best.kode.jwchoice/ui"


console.info("Base:", base)
export default new Router({
	mode: 'history',
	base,
	routes: [

		{
			path: '/finder/:args',
			name: 'finder',
			component: ()=> import("../views/Finder.vue")
		},


		

		{
			path: '/wol/*',
			name: 'wol',
			component: ()=> import("../views/Wol.vue")
		},

		{
			path: '/jweb/*',
			name: 'jweb',
			component: ()=> import("../views/Wol.vue")
		},

		{
			path: '/language/select',
			name: 'languages',
			component: ()=> import("../views/LanguageSelect.vue")
		},

		{
			path: '/language/redir/*',
			name: 'language-redir',
			component: ()=> import("../views/LanguageSelect.vue")
		},

		{
			path: '/bible',
			name: 'bible',
			component: ()=> import("../views/LanguageSelect.vue")
		},

		
		{
			path: '/finder/s/:type/:start/:end/:args',
			name: 'finder',
			component: ()=> import("../views/Finder.vue")
		}
	]
})
