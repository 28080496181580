import Vue from 'vue'
import { Buffer } from 'buffer'
import vuetify from  './plugins/vuetify'
import App from './App.vue'
import router from './plugins/router'
import axios from 'axios'


import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';


// @ts-ignore
window.Buffer = Buffer
axios.defaults.baseURL = "/best.kode.jwchoice"





//Vue.directive('mask', VueMaskDirective)

/*
if(location.host.indexOf("127.0.0.1:") >= 0){
	window["service"] = {
		base: "http://localhost:40801/site/inovanex-player",
		ui: "http://localhost:40801/site/inovanex-player/ui",
		api:"http://localhost:40801/site/inovanex-player/api"
	}
}
else{
	window["service"] = {
		base: "/site/inovanex-player",
		ui: "/site/inovanex-player/ui",
		api: "/site/inovanex-player/api"
	}
}*/


Vue.config.productionTip = false



// @ts-ignore
let params:any = {
	vuetify,
	router,
	render: h => h(App)
}
new Vue(params).$mount('#app')
