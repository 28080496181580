<template>
	<span id="icons">
		<div hidden v-for="Icon in SVG">
			<div v-html="Icon.data" :id="Icon.name" style="font-size:24px"></div>
		</div>
	</span>
</template>
<style>
svg.icon{
	fill: currentColor;
	width: 1em;
	height:1em;
	font-size:24px;
	vertical-align: top;
}
.v-icon svg.icon{
	font-size:inherit;
}
</style>
<script>
import iconPath1 from '../icons/mdi.svg'
import iconPath2 from '../icons/flat.svg'
import axios from 'axios'
export default {

	async created() {
		let urls = [iconPath1, iconPath2]
		let z = 0
		for(let url of urls){
			let response = await axios({
				method: 'GET',
				url: location.protocol + "//" + location.host + url,
				responseType: 'arraybuffer'
			})
			let bytes = Buffer.from(response.data)
			this.SVG.push({
				data: bytes.toString(),
				name: "icon"+ String(z++)
			})
		}

	},
	data(){
		return {
			SVG: []
		}
	}
}
</script>
